import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');

  const showToastMessage = () => {
    toast.success('You successfully subscribed to our newsletter!');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_5a3sxyi';
    const templateId = 'template_x42pbk2';
    const publicKey = 'HVizcSZj-snYOHBtw';

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_email: email,
      to_name: 'PanoQ Team',
      name: email,
      customer_name: email,
      user_email: email,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Subscribed successfully!', response);
        showToastMessage();
        setEmail('');
      })
      .catch((error) => {
        console.error('Error subscribing email:', error);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div
        id="contact"
        className="w-full lg:py-16 text-slate-50 xl:px-36 px-6 bg-gradient-to-r from-slate-900 via-slate-800 via-slate-600 via-sky-950 to-slate-800 "
      >
        <div className="mx-auto grid lg:grid-cols-6">
          <div className="sm:col-span-3 my-4">
            <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
              Stay tuned with 1000+ subscribers
            </h1>
            <p className="font-extralight">
              Sign up to our newsletter and get notified of PanoQ launch
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="sm:col-span-2 my-4 lg:ml-4 subscribeForm"
          >
            <div className="flex sm:flex-row flex-col sm:items-center sm:justify-end items-start w-full">
              <input
                className="lg:w-[400px] w-full p-3 rounded-md text-slate-50 bg-slate-800 border border-cyan-500 focus:border-cyan-300 focus:outline-none focus:ring-2 focus:ring-cyan-500 transition duration-300 ease-in-out"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className="bg-cyan-500 hover:bg-cyan-700 text-slate-50 rounded-md font-medium lg:w-[200px] w-full px-6 py-3 sm:ml-4">
                Notify me
              </button>
            </div>
            <div className="flex justify-center">
              <p className="font-extralight">
                We care about your data. Read our{' '}
                <span className="font-semibold">Privacy Policy.</span>
              </p>
            </div>
          </form>

          <div className="sm:col-span-1 flex sm:justify-end justify-start sm:mb-0 mb-6">
            <div className="sm:p-3">
              <h6 className="md:text-xl sm:text-sm text-lg font-extralight pt-4 pb-2">
                Contact Us
              </h6>
              <a
                href="mailto: hi@panoq.com"
                className=" text-cyan-500 hover:text-cyan-700 active:text-cyan-700 focus:outline-none focus:ring focus:ring-cyan-300 visited:text-cyan-600"
              >
                hi@panoq.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribeForm;

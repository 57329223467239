import Type from './Type';

const Home = () => {
  return (
    <div className="w-full bg-cover bg-center">
      <div className="flex flex-col items-center">
        <h1 className="lg:text-7xl text-5xl text-slate-50 py-6">
          <strong className="text-cyan-500"> PanoQ</strong> {' '}
        </h1>

        <h1 className="lg:text-5xl text-slate-50">
          Where AI and AR Unite Us All
        </h1>

        <div className="lg:p-16 lg:min-w-[600px] align-text-left lg:text-4xl text-cyan-500 pb-8">
          <Type />
        </div>

      </div>
    </div>
  );
};

export default Home;

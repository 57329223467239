import './App.css';
import './components/ParallaxPic.css';
// import EmailForm from './components/EmailForm';
import SubscribeForm from './components/SubscribeForm';
// import EmailRestAPI from './components/EmailRestAPI';
// import Home from './components/Home';
import Navbar from './components/Navbar';
import ParallaxPic from './components/ParallaxPic';
// import PitchDeck from './components/PitchDeck';
import Homepage from './pages/Homepage';
import PitchdeckPage from './pages/PitchdeckPage';


import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";

function App() {
  return (
    <>
    <Router>
      <div className="flex flex-col h-full font-carterone overflow-y-auto ">
        <div className="flex flex-col grow bg-gradient-to-b from-slate-900 via-slate-800 via-slate-600 to-sky-800">
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/pitchdeck" element={<PitchdeckPage />} />
        </Routes>
        </div>
        {/* <div>
          <ParallaxPic />
        </div>
        <SubscribeForm className="grow" /> */}
        {/* <PitchDeck /> */}
      </div>
      </Router>
    </>
  );
}

export default App;

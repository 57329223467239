import Typewriter from 'typewriter-effect';

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "AI Conversations, Real Connections",
          'Redefine Your Social Universe, Virtually',
          'Join the Future of Online Community',
        ],
        autoStart: true,
        loop: true,
        delay: 35,
        deleteSpeed: 35,
      }}
    />
  );
}

export default Type;

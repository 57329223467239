import Home from "../components/Home";
import ParallaxPic from "../components/ParallaxPic";
import SubscribeForm from "../components/SubscribeForm";

function Homepage() {
    return <>
    <Home />
    {/* <div> */}
        <ParallaxPic />
    {/* </div> */}
    <SubscribeForm className="grow" />
    </>
}

export default Homepage;